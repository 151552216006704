import React, { PureComponent, Component } from "react";
import GoldRank from "./Gold_Dec20_Rank.png";
window.onbeforeunload = function () {
  window.scrollTo(0,0);
}
export default function GoldRankDec20() {
  return (
  <div>
    <br/>
    <h3>Consensus Metals Gold - December 2020 Rankings</h3>
    <p className="text-justify">
    The average LBMA daily AM/PM prices for gold in December 2020 was USD1'854/oz. The average of all the consensus gold price forecasts submitted the month was USD1'911/oz. For only the most recent predictions made during the month, the average consensus gold price forecasted was USD1'923/oz. During December, the central themes driving gold forecasts was still the US elections and COVID. Eight of the fifteen predictions submitted referred to the uncertainty surrounding the US elections as impacting the gold price. COVID was mentioned in four of the fifteen forecasts as a significant influence on the gold price. 
    </p>
    <p className="text-justify">
    The total number of forecasts submitted was 15 out of the 16 current registered participants.
    </p>
    <p className="text-justify">
    The gold rankings for December 2020 were as follows, after taking into consideration the date when the forecasts were submitted:
    </p>
    <div className="form-group">
      <table className="table table-bordered">
        <thead>
        <tr>
          <td colspan="1" align="center"><b>Participant</b></td>
          <td colspan="1" align="center"><b>Forecast date</b></td>
          <td colspan="1" align="center"><b>Forecast price</b></td>
          <td colspan="1" align="center"><b>Difference</b></td>
          <td colspan="1" align="center"><b>Unadjusted Score</b></td>
          <td colspan="1" align="center"><b>Adjusted Score</b></td>
          <td colspan="1" align="center"><b>Rank</b></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">25-Nov-20</td>
          <td align="center">$1850/oz</td>
          <td align="center">$4/oz</td>
          <td align="center">99.8%</td>
          <td align="center">99.8%</td>
          <td align="center">1</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">1-Dec-20</td>
          <td align="center">$1800/oz</td>
          <td align="center">$88/oz</td>
          <td align="center">97.1%</td>
          <td align="center">96.6%</td>
          <td align="center">2</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">30-Nov-20</td>
          <td align="center">$1790/oz</td>
          <td align="center">$64/oz</td>
          <td align="center">96.5%</td>
          <td align="center">96.5%</td>
          <td align="center">3</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">5-Oct-20</td>
          <td align="center">$1930/oz</td>
          <td align="center">$76/oz</td>
          <td align="center">95.9%</td>
          <td align="center">95.9%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">25-Nov-20</td>
          <td align="center">$1930/oz</td>
          <td align="center">$76/oz</td>
          <td align="center">95.9%</td>
          <td align="center">95.9%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">8-Oct-20</td>
          <td align="center">$1940/oz</td>
          <td align="center">$86/oz</td>
          <td align="center">95.4%</td>
          <td align="center">95.4%</td>
          <td align="center">6</td>
        </tr>
        <tr>
          <td align="center"><i>MDS</i></td>
          <td align="center">29-Nov-20</td>
          <td align="center">$1760/oz</td>
          <td align="center">$94/oz</td>
          <td align="center">94.9%</td>
          <td align="center">94.9%</td>
          <td align="center">7</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">1-Oct-20</td>
          <td align="center">$1950/oz</td>
          <td align="center">$96/oz</td>
          <td align="center">94.8%</td>
          <td align="center">94.8%</td>
          <td align="center">8</td>
        </tr>
        <tr>
          <td align="center"><i>DocDavis</i></td>
          <td align="center">15-Oct-20</td>
          <td align="center">$1950/oz</td>
          <td align="center">$96/oz</td>
          <td align="center">94.8%</td>
          <td align="center">94.8%</td>
          <td align="center">8</td>
        </tr>
        <tr>
          <td align="center"><i>Thornton</i></td>
          <td align="center">21-Oct-20</td>
          <td align="center">$2000/oz</td>
          <td align="center">$146/oz</td>
          <td align="center">92.1%</td>
          <td align="center">92.1%</td>
          <td align="center">10</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">20-Dec-20</td>
          <td align="center">$1870/oz</td>
          <td align="center">$16/oz</td>
          <td align="center">99.2%</td>
          <td align="center">89.5%</td>
          <td align="center">11</td>
        </tr>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">26-Oct-20</td>
          <td align="center">$2050/oz</td>
          <td align="center">$196/oz</td>
          <td align="center">89.4%</td>
          <td align="center">89.4%</td>
          <td align="center">12</td>
        </tr>
        <tr>
          <td align="center"><i>Metalmoney</i></td>
          <td align="center">19-Oct-20</td>
          <td align="center">$2070/oz</td>
          <td align="center">$216/oz</td>
          <td align="center">88.4%</td>
          <td align="center">88.4%</td>
          <td align="center">13</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">23-Dec-20</td>
          <td align="center">$1870/oz</td>
          <td align="center">$16/oz</td>
          <td align="center">99.2%</td>
          <td align="center">88.0%</td>
          <td align="center">14</td>
        </tr>
        <tr>
          <td align="center"><i>MDS</i></td>
          <td align="center">20-Dec-20</td>
          <td align="center">$1900/oz</td>
          <td align="center">$46/oz</td>
          <td align="center">97.5%</td>
          <td align="center">87.9%</td>
          <td align="center">15</td>
        </tr>
      </tbody>
      </table>
    </div>
<p className="text-justify">
The forecast results for the month can be seen relative to the month's
price movements in the graph below:</p>
<img width={800} height={500} alt="Gold_Dec20_Rank" src={GoldRank} />
<p className="text-justify">
A change between pre-and post-US elections gold price is evident in the chart, with a distinct drop in the gold price around the US elections and a recovery after that. Before the November fall in the gold price, participants appeared to be more bullish in the forecast expectations. Post the US elections, perhaps influenced by the outcome and the fall in the gold price, participants took a more bearish view on the gold price. As the year drew to a close, the forecasts more closely approximated the market price.
</p>
</div>
);
}
