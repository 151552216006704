import React, { PureComponent, Component } from "react";
import CopperRank from "./Copper_Oct_Rank.png";
window.onbeforeunload = function () {
  window.scrollTo(0,0);
}
export default function CopperRankOct20() {
  return (
  <div>
    <br/>
    <h3>Consensus Metals Copper - October 2020 Rankings</h3>
    <p className="text-justify">
    The average LME daily closing price for copper in October 2020 was USD6'702.77/mt. The average of the consensus copper price forecast submitted was USD6'523.13/mt. Based on a visual appraisal of the timing of forecasts submitted, it would appear that the prevailing copper market price influenced participant's predictions at the time they were made, perhaps an indication of an anchoring bias?
    </p>
    <p className="text-justify">
    The total number of forecasts submitted was 8 out of the 15 current registered participants. As participants become more familiar with the forecasting process, it is anticipated more participants will submit forecasts, as well as when the number of new participants increases.
    </p>
    <p className="text-justify">
    The copper rankings for October 2020 were as follows, after taking into consideration the date when the forecast was submitted:
    </p>
    <div className="form-group">
      <table className="table table-bordered">
        <thead>
        <tr>
          <td colspan="1" align="center"><b>Participant</b></td>
          <td colspan="1" align="center"><b>Forecast date</b></td>
          <td colspan="1" align="center"><b>Forecast price</b></td>
          <td colspan="1" align="center"><b>Difference</b></td>
          <td colspan="1" align="center"><b>Unadjusted Score</b></td>
          <td colspan="1" align="center"><b>Adjusted Score</b></td>
          <td colspan="1" align="center"><b>Rank</b></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">1-Oct-20</td>
          <td align="center">$6600/mt</td>
          <td align="center">$103/mt</td>
          <td align="center">98.5%</td>
          <td align="center">98.0%</td>
          <td align="center">1</td>
        </tr>
        <tr>
          <td align="center"><i>User15</i></td>
          <td align="center">7-Oct-20</td>
          <td align="center">$6420/mt</td>
          <td align="center">$283/mt</td>
          <td align="center">95.8%</td>
          <td align="center">95.8%</td>
          <td align="center">2</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">8-Oct-20</td>
          <td align="center">$6735/mt</td>
          <td align="center">$32/mt</td>
          <td align="center">99.5%</td>
          <td align="center">95.6%</td>
          <td align="center">3</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">5-Oct-20</td>
          <td align="center">$6480/mt</td>
          <td align="center">$223/mt</td>
          <td align="center">96.7%</td>
          <td align="center">94.3%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>GoldMiner</i></td>
          <td align="center">4-Oct-20</td>
          <td align="center">$6345/mt</td>
          <td align="center">$358/mt</td>
          <td align="center">94.7%</td>
          <td align="center">92.7%</td>
          <td align="center">5</td>
        </tr>
        <tr>
          <td align="center"><i>Dragqueen</i></td>
          <td align="center">28-Sep-20</td>
          <td align="center">$6195/mt</td>
          <td align="center">$508/mt</td>
          <td align="center">92.4%</td>
          <td align="center">89.0%</td>
          <td align="center">6</td>
        </tr>
        <tr>
          <td align="center"><i>Photosmart</i></td>
          <td align="center">26-Oct-20</td>
          <td align="center">$6615/mt</td>
          <td align="center">$88/mt</td>
          <td align="center">98.7%</td>
          <td align="center">86.1%</td>
          <td align="center">7</td>
        </tr>
        <tr>
          <td align="center"><i>Milan</i></td>
          <td align="center">29-Oct-20</td>
          <td align="center">$6795/mt</td>
          <td align="center">$92/mt</td>
          <td align="center">98.6%</td>
          <td align="center">84.6%</td>
          <td align="center">8</td>
        </tr>
      </tbody>
      </table>
    </div>
<p className="text-justify">
The forecast results for the month can be seen relative to the month's
price movements in the graph below:</p>
<img width={800} height={500} alt="Copper_Oct_Rank" src={CopperRank} />
<p className="text-justify">
Now that October is behind us, remember an element underlying the
research is to investigate if having insight into other experts' 
forecasts can influence our revised forecasts. Please use the 
opportunity to submit your revised forecasts.
</p>
</div>
);
}
