import React, { PureComponent, Component } from "react";
import CopperRank from "./Copper_Dec20_Rank.png";
window.onbeforeunload = function () {
  window.scrollTo(0,0);
}
export default function CopperRankDec20() {
  return (
  <div>
    <br/>
    <h3>Consensus Metals Copper - December 2020 Rankings</h3>
    <p className="text-justify">
    The average LME daily price for copper in December 2020 was USD7'755/mt. The average of all the consensus copper price forecasts submitted the month was USD7'040/mt. For only the most recent predictions made during the month, the average consensus copper price forecasted was USD7'156/mt. During December, the central themes driving copper forecasts were COVID, global economies, China's economic recovery, and US elections. COVID was mentioned in nine of the eighteen predictions as a significant influence on the copper price. The participants made references to the global economic outlook and the Chinese recovery in five of the eighteen forecasts. As with the gold forecasts, participants mentioned the US elections, albeit less often, only in four of the eighteen forecasts. 
    </p>
    <p className="text-justify">
    The total number of forecasts submitted was 18 out of the 18 current registered participants. 
    </p>
    <p className="text-justify">
    The copper rankings for December 2020 were as follows, after taking into consideration the date when the forecasts were submitted:
    </p>
    <div className="form-group">
      <table className="table table-bordered">
        <thead>
        <tr>
          <td colspan="1" align="center"><b>Participant</b></td>
          <td colspan="1" align="center"><b>Forecast date</b></td>
          <td colspan="1" align="center"><b>Forecast price</b></td>
          <td colspan="1" align="center"><b>Difference</b></td>
          <td colspan="1" align="center"><b>Unadjusted Score</b></td>
          <td colspan="1" align="center"><b>Adjusted Score</b></td>
          <td colspan="1" align="center"><b>Rank</b></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">30-Nov-20</td>
          <td align="center">$7750/mt</td>
          <td align="center">$5/mt</td>
          <td align="center">99.9%</td>
          <td align="center">99.9%</td>
          <td align="center">1</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">1-Dec-20</td>
          <td align="center">$7700/mt</td>
          <td align="center">$55/mt</td>
          <td align="center">99.3%</td>
          <td align="center">98.8%</td>
          <td align="center">2</td>
        </tr>
        <tr>
          <td align="center"><i>Metalmoney</i></td>
          <td align="center">19-Oct-20</td>
          <td align="center">$7075/mt</td>
          <td align="center">$680/mt</td>
          <td align="center">91.2%</td>
          <td align="center">91.2%</td>
          <td align="center">3</td>
        </tr>
        <tr>
          <td align="center"><i>Milan</i></td>
          <td align="center">17-Dec-20</td>
          <td align="center">$7825/mt</td>
          <td align="center">$70/mt</td>
          <td align="center">99.1%</td>
          <td align="center">90.9%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>PinkFloyd</i></td>
          <td align="center">2-Dec-20</td>
          <td align="center">$7050/mt</td>
          <td align="center">$705/mt</td>
          <td align="center">90.9%</td>
          <td align="center">89.9%</td>
          <td align="center">5</td>
        </tr>
        <tr>
          <td align="center"><i>Milan</i></td>
          <td align="center">18-Nov-20</td>
          <td align="center">$6950/mt</td>
          <td align="center">$805/mt</td>
          <td align="center">89.6%</td>
          <td align="center">89.6%</td>
          <td align="center">6</td>
        </tr>
        <tr>
          <td align="center"><i>Copper01</i></td>
          <td align="center">25-Nov-20</td>
          <td align="center">$6900/mt</td>
          <td align="center">$855/mt</td>
          <td align="center">89.0%</td>
          <td align="center">89.0%</td>
          <td align="center">7</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">20-Dec-20</td>
          <td align="center">$7875/mt</td>
          <td align="center">$120/mt</td>
          <td align="center">98.5%</td>
          <td align="center">88.8%</td>
          <td align="center">8</td>
        </tr>
        <tr>
          <td align="center"><i>Thornton</i></td>
          <td align="center">21-Oct-20</td>
          <td align="center">$6875/mt</td>
          <td align="center">$880/mt</td>
          <td align="center">88.6%</td>
          <td align="center">88.6%</td>
          <td align="center">9</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">23-Dec-20</td>
          <td align="center">$7775/mt</td>
          <td align="center">$20/mt</td>
          <td align="center">99.7%</td>
          <td align="center">88.6%</td>
          <td align="center">9</td>
        </tr>
        <tr>
          <td align="center"><i>PinkFloyd</i></td>
          <td align="center">29-Oct-20</td>
          <td align="center">$6800/mt</td>
          <td align="center">$955/mt</td>
          <td align="center">87.7%</td>
          <td align="center">87.7%</td>
          <td align="center">11</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">1-Oct-20</td>
          <td align="center">$6750/mt</td>
          <td align="center">$1005/mt</td>
          <td align="center">87.0%</td>
          <td align="center">87.0%</td>
          <td align="center">12</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">5-Oct-20</td>
          <td align="center">$6725/mt</td>
          <td align="center">$1030/mt</td>
          <td align="center">86.7%</td>
          <td align="center">86.7%</td>
          <td align="center">13</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">5-Oct-20</td>
          <td align="center">$6700/mt</td>
          <td align="center">$1055/mt</td>
          <td align="center">86.4%</td>
          <td align="center">86.4%</td>
          <td align="center">14</td>
        </tr>
        <tr>
          <td align="center"><i>JLENNON56</i></td>
          <td align="center">8-Oct-20</td>
          <td align="center">$6575/mt</td>
          <td align="center">$1180/mt</td>
          <td align="center">84.8%</td>
          <td align="center">84.8%</td>
          <td align="center">15</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">8-Oct-20</td>
          <td align="center">$6575/mt</td>
          <td align="center">$1180/mt</td>
          <td align="center">84.8%</td>
          <td align="center">84.8%</td>
          <td align="center">15</td>
        </tr>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">25-Nov-20</td>
          <td align="center">$6450/mt</td>
          <td align="center">$1305/mt</td>
          <td align="center">83.2%</td>
          <td align="center">83.2%</td>
          <td align="center">17</td>
        </tr>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">26-Oct-20</td>
          <td align="center">$6375/mt</td>
          <td align="center">$1380/mt</td>
          <td align="center">82.2%</td>
          <td align="center">82.2%</td>
          <td align="center">18</td>
        </tr>
      </tbody>
      </table>
    </div>
<p className="text-justify">
The forecast results for the month can be seen relative to the month's
price movements in the graph below:</p>
<img width={800} height={500} alt="Copper_Dec20_Rank" src={CopperRank} />
<p className="text-justify">
Post the US elections, the increase significantly, before settling into trading ranging between $ 7'600/mt and $ 8'000/mt. Participants have adjusted their forecast in the last month to consider the rise in the copper price. Considering only the forecasts submitted in December, the consensus average forecast is $ 7'645/mt. What will be interesting is to see how the copper price trades as we move into 2021 and whether it will foreshadow the global economy's state as recovering?
</p>
</div>
);
}
