import React from "react";
window.onbeforeunload = function () {
	window.scrollTo(0, 0);
  }
export default function about() {
  return (
<div>
    <h3>Introduction</h3>
	<p className="text-justify">
	Over the last decade, the total impairments for only the top forty
	mining organizations were around two hundred and forty-eight billion
	U.S. dollars (PWC, 2020). A perennial challenge facing mining
	organizations is how they can reliably forecast metal prices to
	support their planning and improve their sustainability. From the
	inception of a project when cut-off grades are calculated, and
	feasibility studies are undertaken, forecasted metal price
	assumptions play a crucial role. For existing operations, annual plans
	to allocate cash flows to Opex, Capex, taxation, debt servicing,
	dividends, et cetera, are similarly based on assumptions about
	forecasted metal prices. Within the mining industry, the use of
	consensus metal price forecasts for M&amp;A transactions is a
	recognized approach; however, the consensus price derivation usually
	occurs independently of any deliberation between the contributing
	forecasters.
	</p>
      <p className="text-justify">
	It should be feasible for mining organizations to consider interacting
	through existing industry associations to establish a confidential
	forum to make consensus metal price forecasts that include the
	views of organizational members in conjunction with the inputs of
	outside specialist participants. Such an industry co-joint metal
	consensus forecasting panel could combine existing consensus forecasts
	provided by third parties, e.g., Consensus Economics (2020), with the
	insights held by mining industry experts. Within the context of
	publicly traded commodity markets, e.g., COMEX (2020), LBMA (2020), and
	LME (2020), such an industry consensus metal price forecast would be
	independent of 	the markets as the intention behind the collaboration was 
	not aimed at trading on the forecasts. The consensus forecast would be 
	more akin to future price expectations views regularly expressed by 
	industry experts, either as part of their outlook guidance or as the 
	basis for calculating their declared mineral resources and reserves.
	</p>
      <p className="text-justify">
	For specific organizations, when they are making substantial decisions,
	considering another perspective which involves minimal additional
	resources and does not preclude adhering to the inhouse view, would
	seem prudent. As to the confidentiality of competitive insights,
	participation on any industry consensus panel could be structured to
	ensure anonymity, and the resulting consensus metal price forecast
	restricted to only those participating in the derivation. Given mining
	organizations have incurred substantial impairments because of
	metal price fluctuations, should all avenues not be considered to
	mitigate the related risk?
	</p>
      <h3>Background</h3>
	<p className="text-justify">
	Aristotle (Politics, 350 B.C.E) "&hellip; <i>many are better
	judges than a single man ...; for some understand one part, and some
	another, and among them they understand the whole </i>"
	(Everson, 1988, p.66). Condorcet formulated the Law of Averages in 1785
	(Baker, 1976) in his "Jury Theorem." In 1906 Sir Galton found from an
	experiment conducted at an English fair that the "popular vote" could
	prove more accurate than most individual predictions. Surowiecki
	(2004), "<i>The Wisdom of Crowds,</i>" cites examples of
	how acting collectively can result in an outcome surpassing the
	capabilities of the individual group participants. Sunstein (2006), 
	"<i>Infotopia: How Many Minds Produce Knowledge,</i>" explores the 
	value of collective deliberation and the possibilities for open source
	collaboration. Page (2007), "<i>The Difference: How the Power of
	Diversity Creates Better Groups,</i>" explores why diversity is
	more important than sheer numbers in achieving better results. Tetlock
	&amp; Gardner (2016), "<i>Super-Forecasting: The Art and
	Science of Prediction,</i>" examine the potential of collective
	intelligence and collaboration for obtaining the maximum value from
	shared insights. The mathematics of getting better forecasts through
	combing different backgrounds and forecasting methodologies has been
	widely researched, for example, Bachelor and Dua (1995) and Armstrong
	(2001).
	</p>
      <p className="text-justify">
	The idea that through co-operating to share and deliberate insights, a
	benefit could accrue to those participating in the collaboration in the
	form of a more reliable outcome has been around since Aristotle. For
	many consensus forecasts, the process and aggregation mechanism are
	similar to the popular voting approach used by Sir Galton with either
	the mean or median representing the consensus estimate. Hayek (1945)
	described a key feature of markets as their ability to pool diverse
	information and, in the process, communicate that information to other
	market participants allowing them to make better-informed decisions.
	Sunstein (2006) examined how prediction markets could emulate the
	workings of Hayek&rsquo;s markets to communicate information.
	Several organizations offer consensus earnings estimates, with Estimize
	(2020) with almost three thousand indicators covered, and with over one
	hundred thousand estimators, perhaps the most diverse consensus
	estimation service. Of the eighty-five economic indicators Estimize
	covers, commodity prices are not included, opening the possibility for
	the mining industry collectively to explore the opportunity to tap into
	the collective industry insights.
	</p>
      <h3>Research project</h3>
	<p className="text-justify">
	You have been invited to take part in a doctoral research project,
	on a bespoke website. An underlying premise in extending the invitation
	to participate in the research project is that you either prepare or use
	metal price forecasts in your working environment or you
	follow commodities and have insights into emerging market dynamics.
	Participants in the research project includes industry experts from
	mining organizations, commodity trading entities, financial
	institutions, and market research organizations. The research project
	aims to specifically&nbsp;co-opt mining industry professionals to
	participate jointly in making metal price forecasts alongside their
	industry peers. The expected outcome of the research project is to
	establish if a consensus approach for forecasting expected future metal
	prices can prove more reliable for industry stakeholders. The
	interaction amongst participants is on the web-based&nbsp;consensus
	research panel - http://www.comsensusmetals.org.
	</p>
      <p className="text-justify">
	The research project&rsquo;s focus is on forecasted metal prices for 
	use by industry experts within their professional environment. 
	Participation is not expected to require disclosing any
	confidential information or to serve as a basis for any trading or
	hedging decisions. Participation will be anonymous using pseudonyms or
	usernames on the website, to avoid revealing the identity of any
	participants. Should any participant choose to disclose their identity
	on the consensus research panel, it will not alter&nbsp;the
	understanding that participating is only in a&nbsp;private
	capacity. Consequently, any views expressed on the consensus research 
	panel will be regarded solely as those of the participant, and not 
	those of any associated organization. Regardless of any disclosures 
	made on the consensus research panel, in the any research reports, 
	all references to participation will be anonymized and codified, 
	e.g., Industry Expert A, B, C et cetera.
	</p>
      <p className="text-justify">
	Taking part in the research project is not anticipated to cause
	participants any adverse consequences or emotions. The potential
	psychological effects of involvement in the research projects for
	participants should be similar to their experience in performing their
	usual professional responsibilities of either making or using metal
	price forecasts. No financial compensation, reimbursement, or reward
	will be paid to participants for their participation in the research
	project. Participants will have the opportunity to receive an electronic 
	copy of the final research report, should they so choose. Participants 
	will be free to stop participating at any time should they want and have 
	all their past contributions deleted upon request.
	</p>
      <p className="text-justify">
	Panel participants will be expected to contribute metal price
	forecasts regularly over four months (September &ndash; December
	2020), and participation is likely to require approximately thirty
	minutes per month. In making their metal price forecasts, participants
	will&nbsp;be asked to provide a brief (maximum 300 characters) indication 
	of the basis for their forecasted metal prices. Participants are
	encouraged to discuss their forecasts and those submitted by other
	participants, as a prompt to surfacing shared insights, aimed at
	improving the reliability of the consensus forecasted metal prices.
	Should any participant choose to post any link to relevant research on
	the consensus research panel website, it is on the understanding that
	the participant has the necessary right to share the research. As
	the&nbsp;consensus metal price forecasts are the joint effort of
	all participants, access to the contibuting forecasts of all participants
	and&nbsp;the evolving consensus metal price forecasts will continually 
	be available&nbsp;to everyone participating throughout the research
	project.
      </p>
      <h3>Conclusion</h3>
	<p className="text-justify">
	The research project is about the collective interaction
	of participants through iterative cycles of forecasting, sharing
	insights, and&nbsp;aggregating 	the participants' expectations in open 
	view of all participants, to make consensus metal price forecasts. The 
	research project aims to explore the possible benefits of using a diverse 
	consensus panel for forecasting metal prices in the natural resource 
	industry, and through specifically encouraging 	interactions to share and 
	deliberate insights, test the reliability of the approach for forecasting 
	metal prices. If the research project should prove positive, it could assist 
	in structuring longer term consensus forecasts by industry stakeholders, 
	through the auspices of an&nbsp;industry association in future. Ultimately,
	should the idea of tapping into the "wisdom <b>IN </b> 
	the industry" be successful, it may help mining organizations reduce their 
	uncertainty around future metal prices and, consequently, impairments.
	</p>
      <h3>References</h3>
	<p className="text-justify">
	Armstrong, J. S. (2001). <i>Combining Forecasts. In Principles
	of Forecasting, </i> ed. Armstrong, J. S. &nbsp;Boston:
	Kluwer Academic. 
	<br />
	</p>
	<p className="text-justify">Batchelor, R. &amp; Dua, P. (1995).
	&lsquo;Forecaster Diversity
	and the Benefits of Combing Forecasts&rsquo;. &nbsp;<i>Management
	&nbsp;Science</i>, 14(1), pp.68-75.
	<br />
	</p>
	<p className="text-justify">Baker, K. M. (1976). <i>Condorcet,
	Selected Writings. </i>
	Indianapolis: Bobbs-Merrill.
	<br />
	</p>
	<p className="text-justify">
	COMEX (2020). https://www.cmegroup.com/company/comex.html 
	<br />
	</p>
	<p className="text-justify">
	Consensus Economics (2020). https://www.consensuseconomics.com/
	<br />
	</p>
	<p className="text-justify">
	Estimize (2020). https://www.estimize.com/
	<br />
	</p>
	<p className="text-justify">
	Everson, S. (1988). <i>Aristotle, Politics. </i> Jowett
	&amp; Barnes translation. Cambridge: University Press.
	<br />
	</p>
	<p className="text-justify">
	Galton, F. (1907). &lsquo;Vox Populi (The voice of the
	people)&rsquo;. Nature, 75(March 7), pp.450&ndash;51. 
	<br />
	</p>
	<p className="text-justify">
	LBMA (2020). http://www.lbma.org.uk/home
	<br />
	</p>
	<p className="text-justify">
	LME (2020). https://www.lme.com/
	<br />
	</p>
	<p className="text-justify">
	Page, S. E. (2007). <i>The Difference: How the Power of
	Diversity Creates Better Groups, Firms, Schools &nbsp;and
	&nbsp;Societies.</i> New Jersey, U.S.A.: Princeton University
	Press.
	<br />
	</p>
	<p className="text-justify">
	PWC (2020). https://www.pwc.com/
	<br />
	</p>
	<p className="text-justify">
	Sunstein, C. R. (2006). <i>Infotopia: How Many Minds Produce
	Knowledge.</i> Oxford University Press &nbsp;New
	&nbsp;York.
	<br />
	</p>
	<p className="text-justify">
	Surowiecki, J. (2004). <i>The Wisdom of Crowds: Why the Many Are
	Smarter Than the Few and How Collective Wisdom Shapes Business,
	Economies, Societies, and Nations</i>. New York: Doubleday.
	<br />
	</p>
	<p className="text-justify">
	Tetlock P. and Gardner, D. (2016). <i>Super-Forecasting:
	The Art and Science of Prediction</i>. London: Penguin Random House.
	<br />
	</p>    
	</div>
  );
}
