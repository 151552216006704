import React, { PureComponent, Component } from "react";
import CopperRank from "./Copper_Mar21_Rank.png";
import LBMAMedian from "./LBMA_Median_Accuracy.png";
import LBMANorman from "./LBMA_Norman_Accuracy.png";
window.onbeforeunload = function () {
  window.scrollTo(0,0);
}
export default function CopperRankMar21() {
  return (
  <div>
    <br/>
    <h3>Consensus Metals Copper - March 2021 Rankings</h3>
    <p className="text-justify">
    The average LME daily price for copper quarter one 2021 was USD8'504/mt. The average of all the consensus copper price forecasts submitted for the quarter was USD7'383/mt. For only the most recent predictions made for the quarter, the average consensus copper price forecasted was USD7'695/mt, reflecting the sharp upward movement in the copper price in 2021. The general narrative around copper was more positive as references to COVID vaccine rollouts became more frequent. However, there was a split amongst the forecasters on how the vaccine availability would translate into the global economy's recovery. Some participants were cautious in their expectations, anticipating a sluggish recovery. Conversely, other participants saw the likelihood of growing demand and fiscal stimulus as strong supporters of a higher copper price. What is evident from the forecasts, particularly those made in 2020, participants were caught unaware by the substantial rise in the copper price. It will be interesting to see how participants revise their forecasts for the remainder of 2021. 
    </p>
    <p className="text-justify">
    The total number of forecasts submitted was 19 out of the 20 current registered participants. 
    </p>
    <p className="text-justify">
    The copper rankings for March 2021 quarter were as follows, after taking into consideration the date when the forecasts were submitted:
    </p>
    <div className="form-group">
      <table className="table table-bordered">
        <thead>
        <tr>
          <td colspan="1" align="center"><b>Participant</b></td>
          <td colspan="1" align="center"><b>Forecast date</b></td>
          <td colspan="1" align="center"><b>Forecast price</b></td>
          <td colspan="1" align="center"><b>Difference</b></td>
          <td colspan="1" align="center"><b>Unadjusted Score</b></td>
          <td colspan="1" align="center"><b>Adjusted Score</b></td>
          <td colspan="1" align="center"><b>Rank</b></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">31-Dec-20</td>
          <td align="center">$8175/mt</td>
          <td align="center">$329/mt</td>
          <td align="center">96.1%</td>
          <td align="center">96.1%</td>
          <td align="center">1</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">23-Dec-20</td>
          <td align="center">$7875/mt</td>
          <td align="center">$629/mt</td>
          <td align="center">92.6%</td>
          <td align="center">92.6%</td>
          <td align="center">2</td>
        </tr>
        <tr>
          <td align="center"><i>PinkFloyd</i></td>
          <td align="center">21-Dec-20</td>
          <td align="center">$7850/mt</td>
          <td align="center">$654/mt</td>
          <td align="center">92.3%</td>
          <td align="center">92.3%</td>
          <td align="center">3</td>
        </tr>
        <tr>
          <td align="center"><i>Runner006</i></td>
          <td align="center">11-Jan-21</td>
          <td align="center">$7950/mt</td>
          <td align="center">$554/mt</td>
          <td align="center">93.5%</td>
          <td align="center">91.7%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>AndesSA</i></td>
          <td align="center">13-Jan-21</td>
          <td align="center">$7950/mt</td>
          <td align="center">$554/mt</td>
          <td align="center">93.5%</td>
          <td align="center">91.3%</td>
          <td align="center">5</td>
        </tr>
        <tr>
          <td align="center"><i>Frontrunner</i></td>
          <td align="center">11-Jan-21</td>
          <td align="center">$7775/mt</td>
          <td align="center">$729/mt</td>
          <td align="center">91.4%</td>
          <td align="center">89.6%</td>
          <td align="center">6</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">27-Jan-21</td>
          <td align="center">$7950/mt</td>
          <td align="center">$554/mt</td>
          <td align="center">93.5%</td>
          <td align="center">89.0%</td>
          <td align="center">7</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">20-Dec-20</td>
          <td align="center">$7525/mt</td>
          <td align="center">$979/mt</td>
          <td align="center">88.5%</td>
          <td align="center">88.5%</td>
          <td align="center">8</td>
        </tr>
        <tr>
          <td align="center"><i>Copper</i></td>
          <td align="center">31-Jan-21</td>
          <td align="center">$7700/mt</td>
          <td align="center">$804/mt</td>
          <td align="center">90.5%</td>
          <td align="center">88.4%</td>
          <td align="center">9</td>
        </tr>
        <tr>
          <td align="center"><i>PinkFloyd</i></td>
          <td align="center">2-Dec-20</td>
          <td align="center">$7425/mt</td>
          <td align="center">$1079/mt</td>
          <td align="center">87.3%</td>
          <td align="center">87.3%</td>
          <td align="center">10</td>
        </tr>
        <tr>
          <td align="center"><i>Copper</i></td>
          <td align="center">5-Mar-21</td>
          <td align="center">$8200/mt</td>
          <td align="center">$304/mt</td>
          <td align="center">96.4%</td>
          <td align="center">85.8%</td>
          <td align="center">11</td>
        </tr>
        <tr>
          <td align="center"><i>PinkFloyd</i></td>
          <td align="center">29-Oct-20</td>
          <td align="center">$6950/mt</td>
          <td align="center">$1554/mt</td>
          <td align="center">81.7%</td>
          <td align="center">81.7%</td>
          <td align="center">12</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">5-Oct-20</td>
          <td align="center">$6775/mt</td>
          <td align="center">$1729/mt</td>
          <td align="center">79.7%</td>
          <td align="center">79.7%</td>
          <td align="center">13</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">29-Oct-20</td>
          <td align="center">$6775/mt</td>
          <td align="center">$1729/mt</td>
          <td align="center">79.7%</td>
          <td align="center">79.7%</td>
          <td align="center">13</td>
        </tr>
        <tr>
          <td align="center"><i>Thornton</i></td>
          <td align="center">21-Oct-20</td>
          <td align="center">$6750/mt</td>
          <td align="center">$1754/mt</td>
          <td align="center">79.4%</td>
          <td align="center">79.4%</td>
          <td align="center">15</td>
        </tr>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">28-Oct-20</td>
          <td align="center">$6725/mt</td>
          <td align="center">$1779/mt</td>
          <td align="center">79.1%</td>
          <td align="center">79.1%</td>
          <td align="center">16</td>
        </tr>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">11-Jan-21</td>
          <td align="center">$6825/mt</td>
          <td align="center">$1679/mt</td>
          <td align="center">80.3%</td>
          <td align="center">78.4%</td>
          <td align="center">17</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">1-Oct-20</td>
          <td align="center">$6650/mt</td>
          <td align="center">$1854/mt</td>
          <td align="center">78.2%</td>
          <td align="center">78.2%</td>
          <td align="center">18</td>
        </tr>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">25-Nov-20</td>
          <td align="center">$6450/mt</td>
          <td align="center">$2054/mt</td>
          <td align="center">75.8%</td>
          <td align="center">75.8%</td>
          <td align="center">19</td>
        </tr>
      </tbody>
      </table>
    </div>
<p className="text-justify">
The consensus average copper forecast would have ranked 11 if it were included in the above list. The forecast results for the quarter can be seen relative to the copper price over the last two quarters in the graph below:
</p>
<img width={800} height={500} alt="Copper_Mar21_Rank" src={CopperRank} />
<p className="text-justify"><b>General Comments</b></p>
<p className="text-justify">
As the research project has progressed and the data analysis has commenced, I would like to share some preliminary observations about the use of consensus forecast. The London Bullion Market Association ("LBMA") has conducted an annual precious metal survey since 1997. The LBMA survey has some similarities with the current research project, particularly the expectation that each participant includes a commentary with their forecast justifying their price predictions. Over the period 2000 to 2020, 127 analysts have participated in the LBMA survey, with 15 different analysts securing first place for the most annual accurate gold forecast. Amongst the winning analysts, 3 participants scooped first place three times and one other analyst twice, with the remaining analysts only winning once. Based on the winning tallies, it might be expected that the frequent winners would stand out from the consensus measures, but this has not been the case.
</p>
<p className="text-justify">
The consensus median forecast accuracy over the 21 years was 93.7%, and the consensus average 93.6%. By comparison, the respective average accuracy of the top three winners was 94.3% (19 annual forecasts), 93.5% (21 annual forecasts), and 90.8% (15 annual forecasts), with the two-time winner achieving an overall accuracy of 93.5% (21 annual forecasts). Over successive years, the prior year's winners have occasionally fared poorly. The two graphs below show the Consensus Median's respective rankings and the overall most accurate analyst (Ross Norman). If the top analysts' performance were considered examples of mining organizations' in-house view, there would appear to be some merit in using consensus forecasts as a benchmark against which organizational predictions could be judged. Consensus forecasts could provide a gauge for identifying possible extreme views, thereby reducing the consequence of decisions based on specific estimates only. The overall value of consensus forecast is perhaps not in trying to determine a winner in advance, but rather recognizing that the diversity of predictions might better capture the possible range of the outcome, with the aggregating measures providing a standard against which to measure other in-house predictions for potential bias.
</p>
<img width={400} height={250} alt="LBMA_Median_Accuracy" src={LBMAMedian} />
<img width={400} height={250} alt="LBMA_Norman_Accuracy" src={LBMANorman} />
</div>
);
}
