import React, { PureComponent, Component } from "react";
import GoldRank from "./Gold_Mar21_Rank.png";
import LBMAMedian from "./LBMA_Median_Accuracy.png";
import LBMANorman from "./LBMA_Norman_Accuracy.png";
window.onbeforeunload = function () {
  window.scrollTo(0,0);
}
export default function GoldRankMar21() {
  return (
  <div>
    <br/>
    <h3>Consensus Metals Gold - March 2021 Rankings</h3>
    <p className="text-justify">
    The average LBMA daily AM/PM prices for gold in quarter one 2021 was USD1'796/oz. The average of all the consensus gold price forecasts submitted the first quarter of 2021 were USD1'906/oz. For only the most recent predictions made for the quarter, the average consensus gold price forecasted was USD1'869/oz. Amongst the forecasters, the issue of uncertainty appeared in numerous of the comments. Opinions were mixed on whether there would be a positive or negative impetus for gold, with mention made of Bitcoin and safe haven as counter risk possibilities. What has been unexpected is the sharp decline of the gold price in the beginning of 2021, and it will be interesting to see how this influences the Consensus Metals gold price forecasts for the remainder of 2021. 
    </p>
    <p className="text-justify">
    The total number of forecasts submitted was 17 out of the 18 current registered participants.
    </p>
    <p className="text-justify">
    The gold rankings for March 2021 quarter were as follows, after taking into consideration the date when the forecasts were submitted:
    </p>
    <div className="form-group">
      <table className="table table-bordered">
        <thead>
        <tr>
          <td colspan="1" align="center"><b>Participant</b></td>
          <td colspan="1" align="center"><b>Forecast date</b></td>
          <td colspan="1" align="center"><b>Forecast price</b></td>
          <td colspan="1" align="center"><b>Difference</b></td>
          <td colspan="1" align="center"><b>Unadjusted Score</b></td>
          <td colspan="1" align="center"><b>Adjusted Score</b></td>
          <td colspan="1" align="center"><b>Rank</b></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td align="center"><i>Thornton</i></td>
          <td align="center">21-Oct-20</td>
          <td align="center">$1800/oz</td>
          <td align="center">$4/oz</td>
          <td align="center">99.8%</td>
          <td align="center">99.8%</td>
          <td align="center">1</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">31-Dec-20</td>
          <td align="center">$1810/oz</td>
          <td align="center">$14/oz</td>
          <td align="center">99.2%</td>
          <td align="center">99.2%</td>
          <td align="center">2</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">20-Dec-20</td>
          <td align="center">$1850/oz</td>
          <td align="center">$54/oz</td>
          <td align="center">97.0%</td>
          <td align="center">97.0%</td>
          <td align="center">3</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">1-Oct-20</td>
          <td align="center">$1860/oz</td>
          <td align="center">$64/oz</td>
          <td align="center">96.4%</td>
          <td align="center">96.4%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">30-Nov-20</td>
          <td align="center">$1860/oz</td>
          <td align="center">$64/oz</td>
          <td align="center">96.4%</td>
          <td align="center">96.4%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>MDS</i></td>
          <td align="center">28-Dec-20</td>
          <td align="center">$1860/oz</td>
          <td align="center">$64/oz</td>
          <td align="center">96.4%</td>
          <td align="center">96.4%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">23-Dec-20</td>
          <td align="center">$1870/oz</td>
          <td align="center">$74/oz</td>
          <td align="center">95.9%</td>
          <td align="center">95.9%</td>
          <td align="center">7</td>
        </tr>
        <tr>
          <td align="center"><i>MDS</i></td>
          <td align="center">9-Jan-21</td>
          <td align="center">$1850/oz</td>
          <td align="center">$54/oz</td>
          <td align="center">97.0%</td>
          <td align="center">95.5%</td>
          <td align="center">8</td>
        </tr>
        <tr>
          <td align="center"><i>Frontrunner</i></td>
          <td align="center">11-Jan-21</td>
          <td align="center">$1740/oz</td>
          <td align="center">$56/oz</td>
          <td align="center">96.9%</td>
          <td align="center">95.1%</td>
          <td align="center">9</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">27-Jan-21</td>
          <td align="center">$1860/oz</td>
          <td align="center">$64/oz</td>
          <td align="center">96.4%</td>
          <td align="center">91.9%</td>
          <td align="center">10</td>
        </tr>
        <tr>
          <td align="center"><i>Runner006</i></td>
          <td align="center">11-Jan-21</td>
          <td align="center">$1910/oz</td>
          <td align="center">$114/oz</td>
          <td align="center">93.7%</td>
          <td align="center">91.8%</td>
          <td align="center">11</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">5-Oct-20</td>
          <td align="center">$1960/oz</td>
          <td align="center">$164/oz</td>
          <td align="center">90.9%</td>
          <td align="center">90.9%</td>
          <td align="center">12</td>
        </tr>
        <tr>
          <td align="center"><i>MDS</i></td>
          <td align="center">7-Feb-21</td>
          <td align="center">$1850/oz</td>
          <td align="center">$54/oz</td>
          <td align="center">97.1%</td>
          <td align="center">90.7%</td>
          <td align="center">13</td>
        </tr>
        <tr>
          <td align="center"><i>DocDavis</i></td>
          <td align="center">15-Oct-20</td>
          <td align="center">$1970/oz</td>
          <td align="center">$174/oz</td>
          <td align="center">90.3%</td>
          <td align="center">90.3%</td>
          <td align="center">14</td>
        </tr>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">11-Jan-21</td>
          <td align="center">$2030/oz</td>
          <td align="center">$234/oz</td>
          <td align="center">87.0%</td>
          <td align="center">85.1%</td>
          <td align="center">15</td>
        </tr>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">25-Nov-20</td>
          <td align="center">$2080/oz</td>
          <td align="center">$284/oz</td>
          <td align="center">84.2%</td>
          <td align="center">84.2%</td>
          <td align="center">16</td>
        </tr>
        <tr>
          <td align="center"><i>photosmart</i></td>
          <td align="center">28-Oct-20</td>
          <td align="center">$2240/oz</td>
          <td align="center">$444/oz</td>
          <td align="center">75.3%</td>
          <td align="center">75.3%</td>
          <td align="center">17</td>
        </tr>
      </tbody>
      </table>
    </div>
<p className="text-justify">
The consensus average gold forecast would have ranked 10 if it were included in the above list. The forecast results for the month can be seen relative to the month's price movements in the graph below:
</p>
<img width={800} height={500} alt="Gold_Mar21_Rank" src={GoldRank} />
<p className="text-justify"><b>General Comments</b></p>
<p className="text-justify">
As the research project has progressed and the data analysis has commenced, I would like to share some preliminary observations about the use of consensus forecast. The London Bullion Market Association ("LBMA") has conducted an annual precious metal survey since 1997. The LBMA survey has some similarities with the current research project, particularly the expectation that each participant includes a commentary with their forecast justifying their price predictions. Over the period 2000 to 2020, 127 analysts have participated in the LBMA survey, with 15 different analysts securing first place for the most annual accurate gold forecast. Amongst the winning analysts, 3 participants scooped first place three times and one other analyst twice, with the remaining analysts only winning once. Based on the winning tallies, it might be expected that the frequent winners would stand out from the consensus measures, but this has not been the case.
</p>
<p className="text-justify">
The consensus median forecast accuracy over the 21 years was 93.7%, and the consensus average 93.6%. By comparison, the respective average accuracy of the top three winners was 94.3% (19 annual forecasts), 93.5% (21 annual forecasts), and 90.8% (15 annual forecasts), with the two-time winner achieving an overall accuracy of 93.5% (21 annual forecasts). Over successive years, the prior year's winners have occasionally fared poorly. The two graphs below show the Consensus Median's respective rankings and the overall most accurate analyst (Ross Norman). If the top analysts' performance were considered examples of mining organizations' in-house view, there would appear to be some merit in using consensus forecasts as a benchmark against which organizational predictions could be judged. Consensus forecasts could provide a gauge for identifying possible extreme views, thereby reducing the consequence of decisions based on specific estimates only. The overall value of consensus forecast is perhaps not in trying to determine a winner in advance, but rather recognizing that the diversity of predictions might better capture the possible range of the outcome, with the aggregating measures providing a standard against which to measure other in-house predictions for potential bias.    
</p>
<img width={400} height={250} alt="LBMA_Median_Accuracy" src={LBMAMedian} />
<img width={400} height={250} alt="LBMA_Norman_Accuracy" src={LBMANorman} />
</div>
);
}
