import React, { PureComponent, Component } from "react";
import CopperRank from "./Copper_Nov20_Rank.png";
window.onbeforeunload = function () {
  window.scrollTo(0,0);
}
export default function CopperRankNov20() {
  return (
  <div>
    <br/>
    <h3>Consensus Metals Copper - November 2020 Rankings</h3>
    <p className="text-justify">
    The average LME daily price for copper in November 2020 was USD7'063/mt. The average of all the consensus copper price forecasts submitted during the month was USD6'823/mt. For only the most recent predictions made during the month, the average consensus copper price forecasted was USD6'931/mt, only 1.9 percent less than the actual monthly average. During November, the central theme driving copper forecasts was the impact of COVID-19 on the world economy and the possible shift in US policy towards China following the Democrats' victory in the US elections. There was a spread of opinion on whether the impact of COVID-19 would carry through into 2021 and dampen prices. 
    </p>
    <p className="text-justify">
    The total number of forecasts submitted was 12 out of the 18 current registered participants. 
    </p>
    <p className="text-justify">
    In the interest of fairness, if forecasts were submitted sooner rather than later, those participants making forecasts earlier were penalized less in scoring their predictions. The forecast timing moderator adjusts for forecasts made later in the forecasting cycle when the outcome is known to a greater degree. The adjustment is 0.5% per day (1/30 X 15%) reduction in the original score after the 1st of the month forecasted. The copper rankings for November 2020 were as follows, after taking into consideration the date when the forecasts were submitted:
    </p>
    <div className="form-group">
      <table className="table table-bordered">
        <thead>
        <tr>
          <td colspan="1" align="center"><b>Participant</b></td>
          <td colspan="1" align="center"><b>Forecast date</b></td>
          <td colspan="1" align="center"><b>Forecast price</b></td>
          <td colspan="1" align="center"><b>Difference</b></td>
          <td colspan="1" align="center"><b>Unadjusted Score</b></td>
          <td colspan="1" align="center"><b>Adjusted Score</b></td>
          <td colspan="1" align="center"><b>Rank</b></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td align="center"><i>Thornton</i></td>
          <td align="center">21-Oct-20</td>
          <td align="center">$6900/mt</td>
          <td align="center">$163/mt</td>
          <td align="center">97.7%</td>
          <td align="center">97.7%</td>
          <td align="center">1</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">8-Oct-20</td>
          <td align="center">$6750/mt</td>
          <td align="center">$313/mt</td>
          <td align="center">95.6%</td>
          <td align="center">95.6%</td>
          <td align="center">2</td>
        </tr>
        <tr>
          <td align="center"><i>User15</i></td>
          <td align="center">7-Oct-20</td>
          <td align="center">$6725/mt</td>
          <td align="center">$338/mt</td>
          <td align="center">95.2%</td>
          <td align="center">95.2%</td>
          <td align="center">3</td>
        </tr>
        <tr>
          <td align="center"><i>PinkFloyd</i></td>
          <td align="center">29-Oct-20</td>
          <td align="center">$6700/mt</td>
          <td align="center">$363/mt</td>
          <td align="center">94.9%</td>
          <td align="center">94.9%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">1-Oct-20</td>
          <td align="center">$6625/mt</td>
          <td align="center">$438/mt</td>
          <td align="center">93.8%</td>
          <td align="center">93.8%</td>
          <td align="center">5</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">5-Oct-20</td>
          <td align="center">$6475/mt</td>
          <td align="center">$588/mt</td>
          <td align="center">91.7%</td>
          <td align="center">91.7%</td>
          <td align="center">6</td>
        </tr>
        <tr>
          <td align="center"><i>Photosmart</i></td>
          <td align="center">26-Oct-20</td>
          <td align="center">$6475/mt</td>
          <td align="center">$588/mt</td>
          <td align="center">91.7%</td>
          <td align="center">91.7%</td>
          <td align="center">6</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">17-Nov-20</td>
          <td align="center">$7025/mt</td>
          <td align="center">$38/mt</td>
          <td align="center">99.5%</td>
          <td align="center">91.0%</td>
          <td align="center">8</td>
        </tr>
        <tr>
          <td align="center"><i>Milan</i></td>
          <td align="center">18-Nov-20</td>
          <td align="center">$7050/mt</td>
          <td align="center">$13/mt</td>
          <td align="center">99.8%</td>
          <td align="center">90.8%</td>
          <td align="center">9</td>
        </tr>
        <tr>
          <td align="center"><i>Copper01</i></td>
          <td align="center">25-Nov-20</td>
          <td align="center">$6900/mt</td>
          <td align="center">$163/mt</td>
          <td align="center">97.7%</td>
          <td align="center">85.2%</td>
          <td align="center">10</td>
        </tr>
        <tr>
          <td align="center"><i>Photosmart</i></td>
          <td align="center">25-Nov-20</td>
          <td align="center">$6575/mt</td>
          <td align="center">$488/mt</td>
          <td align="center">93.1%</td>
          <td align="center">80.6%</td>
          <td align="center">11</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">30-Nov-20</td>
          <td align="center">$7750/mt</td>
          <td align="center">$686/mt</td>
          <td align="center">90.3%</td>
          <td align="center">75.3%</td>
          <td align="center">12</td>
        </tr>
      </tbody>
      </table>
    </div>
<p className="text-justify">
The forecast results for the month can be seen relative to the month's
price movements in the graph below:</p>
<img width={800} height={500} alt="Copper_Nov20_Rank" src={CopperRank} />
<p className="text-justify">
A change between pre-and post-US elections copper price is evident in the chart, with a distinct climb in the copper price as the US election results became more certain. Whether it is that participants expected a more muted reaction to the US elections and a copper price range of around the USD6'800/mt level, or a less dramatic response to the COVID-19 vaccine news and the possible implications for the world economy is not clear. As mentioned by some participants, the driving force behind the copper price is the metals fundamentals and the influence of global economic factors, e.g., the weakening US dollar. Another contributing factor appears to be the increasingly bullish sentiment of commodity traders, as futures positions have increased significantly of late.
</p>
<p className="text-justify">
From a research perspective, testing the idea that interacting with peers could result in a more reliable outcome has not been thoroughly examined. Few participants are using the possibility to comment on others' views and the evolving consensus price forecast. In the usual commodity price polls, participants are only expected to submit a price forecast, which is then aggregated to determine a consensus price. An element of the research project is seeing if providing a justification makes the deliberation process more concrete and surfaces any biases in making the forecast. In a complementary way, making sure all participants can see both the forecasts and thought processes of other participants, it is hoped the shared insights might contribute to a more reliable consensus forecast. The research idea is to move from the concept of the "wisdom of the crowd" to the "wisdom of the select experts" by encouraging joint deliberation to tap into the diversity of participants' insights and from these shared interactions develop a more reliable consensus copper price forecast. An underlying expectation from the research is expertise is more important than the sheer number of participants, explaining why participation was focused on industry experts.
</p>
<p className="text-justify">
It would be appreciated if participants could review the forecasts and comments of other participants and remark on these when submitting their revised forecasts. Participants are also encouraged to make forecasts as often as they like if they feel their previous predictions are no longer accurate.
</p>
<p className="text-justify">
To my surprise, the metal prices moved more than I had anticipated necessitating a recalibration of the forecast price range. The past forecasts have been adjusted to keep the previous average forecasted price more or less the same as had previously been calculated.
</p>
</div>
);
}
