import React, { PureComponent, Component } from "react";
import GoldRank from "./Gold_Oct_Rank.png";
window.onbeforeunload = function () {
  window.scrollTo(0,0);
}
export default function GoldRankOct20() {
  return (
  <div>
    <br/>
    <h3>Consensus Metals Gold - October 2020 Rankings</h3>
    <p className="text-justify">
      The average LBMA daily AM/PM prices for gold in October 2020 was
      USD1'901.40/oz. The average of the consensus gold price forecast
      submitted was USD1930/oz. Two common themes appeared to be the main
      drivers behind the gold forecasts for October, uncertainty around the
      US elections, and the impact of COVID. Both elements were seen as
      positive for gold as a safe-haven asset in times of political upheaval. 
      Some participants were a lot more bullish on the potential impact on 
      gold than other more conservative forecasters.
    </p>
    <p className="text-justify">
      The total number of forecasts submitted was 6 out of the 13 current
      registered participants. As participants become more familiar with the
      forecasting process, it is anticipated more participants will submit
      forecasts, as well as when the number of new participants increases.
    </p>
    <p className="text-justify">
      The gold rankings for October 2020 were as follows, after taking into
      consideration the date when the forecast was submitted:
    </p>
    <div className="form-group">
      <table className="table table-bordered">
        <thead>
        <tr>
          <td colspan="1" align="center"><b>Participant</b></td>
          <td colspan="1" align="center"><b>Forecast date</b></td>
          <td colspan="1" align="center"><b>Forecast price</b></td>
          <td colspan="1" align="center"><b>Difference</b></td>
          <td colspan="1" align="center"><b>Unadjusted Score</b></td>
          <td colspan="1" align="center"><b>Adjusted Score</b></td>
          <td colspan="1" align="center"><b>Rank</b></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">5-Oct-20</td>
          <td align="center">$1900/oz</td>
          <td align="center">$1.4/oz</td>
          <td align="center">99.2%</td>
          <td align="center">97.2%</td>
          <td align="center">1</td>
        </tr>
        <tr>
          <td align="center"><i>DocDavis</i></td>
          <td align="center">15-Oct-20</td>
          <td align="center">$1900/oz</td>
          <td align="center">$1.4/oz</td>
          <td align="center">99.2%</td>
          <td align="center">92.2%</td>
          <td align="center">2</td>
        </tr>
        <tr>
          <td align="center"><i>GoldMiner</i></td>
          <td align="center">4-Oct-20</td>
          <td align="center">$1940/oz</td>
          <td align="center">$38.6/oz</td>
          <td align="center">78.2%</td>
          <td align="center">76.7%</td>
          <td align="center">3</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">1-Oct-20</td>
          <td align="center">$1945/oz</td>
          <td align="center">$43.6/oz</td>
          <td align="center">75.4%</td>
          <td align="center">75.4%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">8-Oct-20</td>
          <td align="center">$1945/oz</td>
          <td align="center">$43.6/oz</td>
          <td align="center">75.4%</td>
          <td align="center">71.9%</td>
          <td align="center">5</td>
        </tr>
        <tr>
          <td align="center"><i>Photosmart</i></td>
          <td align="center">26-Oct-20</td>
          <td align="center">$1950/oz</td>
          <td align="center">$48.6/oz</td>
          <td align="center">72.6%</td>
          <td align="center">60.1%</td>
          <td align="center">6</td>
        </tr>
      </tbody>
      </table>
    </div>
<p className="text-justify">
The forecast results for the month can be seen relative to the month's
price movements in the graph below:</p>
<img width={800} height={500} alt="Gold_Oct_Rank" src={GoldRank} />
<p className="text-justify">
Now that October is behind us, remember an element underlying the
research is to investigate if having insight into other experts' 
forecasts can influence our revised forecasts. Please use the 
opportunity to submit your revised forecasts.
</p>
</div>
);
}
