import React from "react";
window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}
export default function PConsent() {
  return (
  <div>
    <div>
      <h3>Research Addition Information</h3>
      </div>
      <div>
       <table className="table table-bordered">
        <thead></thead>
        <tbody>
          <tr>
            <td>
            <p class="text-justify">
              <b>Title of Research Project:</b> 
             </p>
             <p class="text-justify"> 
              How will a consensus panel approach for forecasting copper and gold prices, which
              specifically includes mining industry experts’ participation,
              improve the reliability of forecast price estimates for planning
              purposes?
             </p> 
            </td>
          </tr>
          <tr>
            <td>
            <p class="text-justify">
              <b>Short Title: </b>
             </p>
             <p class="text-justify"> 
              How can consensus copper and gold price
              forecasting be more effectively used in the mining industry for
              planning?
             </p> 
            </td>
          </tr>
          <tr>
            <td>
              <b>Researcher:</b> John Lamprecht
              <br />
              <b>University Email:</b> John.Lamprecht@online.liverpool.ac.uk
              <br />
              <b>Telephone:</b> +44 151 528 5728
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>Invitation</b>
               </p> 
               <p class="text-justify">
                You are being invited to take part in a research project. Before
                you decide whether to participate, it is important for you to
                understand why the research is taking place and what it will
                involve. Please take the time to read the following information
                carefully and feel free to ask us if you would like more
                information or if there is anything that you do not understand.
                We would like to stress that you do not have to accept the
                invitation and should only agree to take part if you want to. An 
		            underlying premise in extending the invitation to participate in 
		            the research project is that you either prepare or use metal price 
		            forecasts in your working environment or you follow commodities 
		            and have insights into emerging market dynamics. If this assumption 
		            is not correct, please indicate “No” on the Registration Page and 
		            decline the opportunity to participate in the research project. 
               </p> 
              </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>What is the purpose of the research project?</b>
              </p>   
              <p class="text-justify"> 
                A perennial problem facing mining organizations is how they can more 
                reliably forecast metal prices to consistently support their planning
                and improve their sustainability. The intention of the research project 
		            aims to explore if co-opting mining industry professionals to participate 
		            in in making metal price forecasts alongside their industry peers, 
		            and jointly surface insights about expected metal prices to reduce the 
		            price uncertainty for all participants involved in the research project."
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">         
                <b>Why have I been chosen to take part?</b>
               </p> 
               <p class="text-justify"> 
                Participants in the research project includes industry experts from
		            mining organizations, commodity trading entities, financial
		            institutions, and market research organizations. You have been
                chosen because of your experience within or to the natural resource
                industry. On the basis that the nature of the research project
                closely mirrors the participant’s professional responsibilities
                in making or using metal price forecasts, it is expected
                participation in the research panel will require minimal
                additional time from participants (approximately 30 mintutes per month). 
		            An assumption is that amongst the actions you regularly undertake is 
		            preparing or using metal price forecasts, should this not be part of 
		            your experiences, please indicate “No” on the Registration Page and 
		            decline participation in the research project."
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>Do I have to take part?</b>
              </p>
              <p class="text-justify">
                It is entirely up to you to decide whether you want to
                participate in the research project. If you do choose to proceed, you
                should indicate your consent on the Registration Page. You are
                free to withdraw at any time from the research project without
                providing a reason or risk incurring any repercussions.
                Participation will be anonymous using a pseudonyms or usernames 
		            you choose so as not to reveal your identity. Should you however 
		            choose to disclose your identity on the consensus panel, it will be
                on the understanding that you are specifically participating in
                your personal capacity and the views expressed are your own and
                not those of any organization.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>What will happen if I take part?</b>
              </p>
              <p class="text-justify">
                You will be asked to participate in a metal price forecasts
                and discussion concerning regarding your expectations of metal 
                prices over the coming months. In making such predictions you
                will be asked to provide a brief (maximum 300 characters) 
		            indication of the basis for your forecasts. Panel participants 
		            will be permitted and encouraged to discuss all forecasts and views
                submitted. Should you want to post any relevant metal price
                forecast research on the consensus panel this will be
                permitted, but you should ensure you have the right and necessary 
                authority to share the document. In the final research
                report, all consensus panel participation references will be
                anonymized, and codified, e.g., Industry Expert A, B, C et
                cetera. There are no other commitments or restrictions
                associated with participating. In compliance with the University
                of Liverpool’s research data management policy, the Research project data
                will be stored for five years. Under freedom of information
                legislation, you are entitled to request access the information
                you have provided at any time.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>Expenses and/or compensation</b>
               </p>
               <p class="text-justify">  
                Participants should not incur any additional expenses as a
                result of taking part in this research project. No compensation
                will be paid to panel participants or any incidental expenses
                refunded.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>Are there any risks in taking part?</b>
              </p>
              <p class="text-justify">
                Taking part in this research project is not anticipated to cause
                you any adverse consequences or emotions. The potential physical
                or psychological effects of involvement in the research projects
                for participants should be the same as you experience in
                performing your usual professional task of making or using metal
                price forecasts. However, should you experience any discomfort
                from taking part in this research project, please contact the
                researcher or supervisor to address the reason immediately.
                <br />
                Researcher: John Lamprecht Email: John.Lamprecht@online.liverpool.ac.uk
                <br />
                Supervisor: Jim Hanly Email: Jim.Hanly@online.liverpool.ac.uk
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
               </p>
               <p class="text-justify"> 
                <b>Are there any benefits in taking part?</b>
                </p>
                <p class="text-justify"> 
                While there are not necessarily immediate benefits for those
                participating in the research project, it is hoped that
                this research project will have a beneficial impact on the
                reliability of forecasted metal prices used by industry
                experts in the natural resource sector. As the research project
                is an iterative cycle of forecasting, deliberating and
                reforecasting metal price estimates, with the ongoing results
                continuously shared with all panel participants, it is hoped the
                research project will help inform the professional practice of 
                the participants in future. The research project could also serve
		            as a precursor to a similar project by an industry association on 
		            behalf of its members, including mining organizations, in future.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>If you have any concerns, complaints or if there are any
                  problems or questions you feel have not been adequately
                  addressed?</b>
              </p>
              <p class="text-justify">  
                If you are unhappy, or if you have any complaints about the
                research project in the first instance, please feel free to let
                us know by contacting the researcher or supervisor, and we will try to
                help. If you remain unhappy or have a complaint which you feel
                you cannot come to us with, then, you should contact:
                <br />
                Research Governance Officer at Email: ethics@liv.ac.uk, or 
                <br />
                University of Liverpool Email: Liverpoolethics@ohecampus.com. 
                <br />
                When contacting the Research Governance Officer or Liverpool 
                Research Participant Advocate, please provide details of the name 
                or description of the research project (so that it can be identified), 
                the researcher(s) involved, and the particulars of the complaint you 
                wish to make.
                <br />
                For participants from the United States of America, the
                following contact details can be utilized: 
                <br />
                USA number +1-612-312-1210 or email address: Liverpoolethics@ohecampus.com
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>Will my participation be kept confidential?</b>
               </p>
               <p class="text-justify">  
                All the information that we collect about you during the
                research project will be kept strictly confidential. You will
                not be able to be identified or identifiable in any reports or
                publications. Your institution will also not be identified or
                identifiable. Any data collected about you during discussions
                will be stored online in a password-protected form. Data
                collected may be shared in an anonymised form to allow reuse by
                other research teams. As stated in point 12 below, you may
                terminate your participation at any time and stipulate how your
                past participation is to be handled. As outline above, all 
                references in the research report will be anonymised to
                prevent any individuals or their institutions from being
                identified.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>What will happen to the results of the research project?</b>
              </p>
              <p class="text-justify">
                The results of the research project will be presented in a DBA thesis, 
                and as per University of Liverpool’s research data policy, the data
                produced during this research will be made openly available to
                the broader academic community. You or your organization will
                not be identified in any report or publication. If you wish to
                receive an electronic copy of any reports resulting from the
                research, please indicate so on the Registration Page and you
                will be added to the circulation list.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>What will happen if I want to stop taking part?</b>
              </p>
              <p class="text-justify">
                You can withdraw from participating in this research project at
                any time, without explanation. Results up to the period of
                withdrawal may be used if you are happy for this to be done.
                Otherwise, you may request that their destruction and no further
                use will be made of them."
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>Who can I contact if I have further questions?</b>
              </p>
              <p class="text-justify">
                For any further question, please contact the researcher or the
                research supervisor. 
                <br />
                Researcher: John Lamprecht Email: John.Lamprecht@online.liverpool.ac.uk 
                <br />
                Supervisor: Dr. Jim Hanly Email: Jim.Hanly@online.liverpool.ac.uk
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-justify">
                <b>Thank you for taking part in this research project.</b>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  );
}
