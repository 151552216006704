import React, { PureComponent, Component } from "react";
import GoldRank from "./Gold_Nov20_Rank.png";
window.onbeforeunload = function () {
  window.scrollTo(0,0);
}
export default function GoldRankNov20() {
  return (
  <div>
    <br/>
    <h3>Consensus Metals Gold - November 2020 Rankings</h3>
    <p className="text-justify">
    The average LBMA daily AM/PM prices for gold in November 2020 was USD1'866.5/oz. The average of all the consensus gold price forecasts submitted during the month was USD1'910/oz. For only the most recent predictions made during the month, the average consensus gold price forecasted was USD1'877/oz, only 0.6 percent greater than the actual monthly average. During November, the central theme driving gold forecasts was the US elections and the implications of a Biden win. What was evident from the comments was the diversity of opinion on how Biden's victory would influence the gold price in the future and the impact on the US dollar exchange rate. 
    </p>
    <p className="text-justify">
    The total number of forecasts submitted was 11 out of the 16 current registered participants.
    </p>
    <p className="text-justify">
    In the interest of fairness, if forecasts were submitted sooner rather than later, those participants making forecasts earlier were penalized less in scoring their predictions. The forecast timing moderator adjusts for forecasts made later in the forecasting cycle when the outcome is known to a greater degree. The adjustment is 0.5% per day (1/30 X 15%) reduction in the original score after the 1st of the month forecasted. The gold rankings for November 2020 were as follows, after taking into consideration the date when the forecasts were submitted:
    </p>
    <div className="form-group">
      <table className="table table-bordered">
        <thead>
        <tr>
          <td colspan="1" align="center"><b>Participant</b></td>
          <td colspan="1" align="center"><b>Forecast date</b></td>
          <td colspan="1" align="center"><b>Forecast price</b></td>
          <td colspan="1" align="center"><b>Difference</b></td>
          <td colspan="1" align="center"><b>Unadjusted Score</b></td>
          <td colspan="1" align="center"><b>Adjusted Score</b></td>
          <td colspan="1" align="center"><b>Rank</b></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">5-Oct-20</td>
          <td align="center">$1930/oz</td>
          <td align="center">$63.5/oz</td>
          <td align="center">96.6%</td>
          <td align="center">96.6%</td>
          <td align="center">1</td>
        </tr>
        <tr>
          <td align="center"><i>DocDavis</i></td>
          <td align="center">15-Oct-20</td>
          <td align="center">$1940/oz</td>
          <td align="center">$73.5/oz</td>
          <td align="center">96.1%</td>
          <td align="center">96.1%</td>
          <td align="center">2</td>
        </tr>
        <tr>
          <td align="center"><i>JcL</i></td>
          <td align="center">8-Oct-20</td>
          <td align="center">$1950/oz</td>
          <td align="center">$83.5/oz</td>
          <td align="center">95.5%</td>
          <td align="center">95.5%</td>
          <td align="center">3</td>
        </tr>
        <tr>
          <td align="center"><i>MDS</i></td>
          <td align="center">3-Nov-20</td>
          <td align="center">$1930/oz</td>
          <td align="center">$63.5/oz</td>
          <td align="center">96.1%</td>
          <td align="center">95.1%</td>
          <td align="center">4</td>
        </tr>
        <tr>
          <td align="center"><i>Thornton</i></td>
          <td align="center">21-Oct-20</td>
          <td align="center">$1960/oz</td>
          <td align="center">$93.5/oz</td>
          <td align="center">95.0%</td>
          <td align="center">95.0%</td>
          <td align="center">5</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">1-Oct-20</td>
          <td align="center">$1990/oz</td>
          <td align="center">$123.5/oz</td>
          <td align="center">93.4%</td>
          <td align="center">93.4%</td>
          <td align="center">6</td>
        </tr>
        <tr>
          <td align="center"><i>Photosmart</i></td>
          <td align="center">26-Oct-20</td>
          <td align="center">$2020/oz</td>
          <td align="center">$153.5/oz</td>
          <td align="center">91.8%</td>
          <td align="center">91.8%</td>
          <td align="center">7</td>
        </tr>
        <tr>
          <td align="center"><i>Justin</i></td>
          <td align="center">17-Nov-20</td>
          <td align="center">$1880/oz</td>
          <td align="center">$13.5/oz</td>
          <td align="center">99.3%</td>
          <td align="center">90.8%</td>
          <td align="center">8</td>
        </tr>
        <tr>
          <td align="center"><i>Photosmart</i></td>
          <td align="center">25-Nov-20</td>
          <td align="center">$1860/oz</td>
          <td align="center">$6.5/oz</td>
          <td align="center">99.7%</td>
          <td align="center">87.2%</td>
          <td align="center">9</td>
        </tr>
        <tr>
          <td align="center"><i>RockDoctor</i></td>
          <td align="center">30-Nov-20</td>
          <td align="center">$1800/oz</td>
          <td align="center">$66.5/oz</td>
          <td align="center">96.4%</td>
          <td align="center">81.4%</td>
          <td align="center">10</td>
        </tr>
        <tr>
          <td align="center"><i>MDS</i></td>
          <td align="center">29-Nov-20</td>
          <td align="center">$1750/oz</td>
          <td align="center">$116.5/oz</td>
          <td align="center">93.8%</td>
          <td align="center">79.3%</td>
          <td align="center">11</td>
        </tr>
      </tbody>
      </table>
    </div>
<p className="text-justify">
The forecast results for the month can be seen relative to the month's
price movements in the graph below:</p>
<img width={800} height={500} alt="Gold_Nov20_Rank" src={GoldRank} />
<p className="text-justify">
A change between pre-and post-US elections gold price is evident in the chart, with a distinct drop in the gold price as the US election results became more certain. Whether it is that participants expected a continuation of the gold price trading range of around the USD1'900/oz level regardless of the US election outcome, or that participants had not expected the US election results to have a material bearing on the gold price is not known. Some participants saw the gold price change as a reduction in the political uncertainty following President Trump's loss. In contrast, others saw the movement as the market, reacting to differing economic drivers.
</p>
<p className="text-justify">
From a research perspective, testing the idea that through interacting with peers could result in a more reliable outcome has not been thoroughly examined. Few participants are using the possibility to comment on others' views and the evolving consensus price forecast. In the usual commodity price polls, participants are only expected to submit a price forecast, which is then aggregated to determine a consensus price. An element of the research project is seeing if providing a justification makes the deliberation process more concrete and surfaces any biases in making the forecast. In a complementary way, making sure all participants can see both the forecasts and thought processes of other participants, it is hoped the shared insights might contribute to a more reliable consensus forecast. The research idea is to move from the concept of the "wisdom of the crowd" to the "wisdom of the select experts" by encouraging joint deliberation to tap into the diversity of participants' insights and from these shared interactions develop a more reliable consensus copper price forecast. An underlying expectation from the research is expertise is more important than the sheer number of participants, explaining why participation was focused on industry experts.
</p>
<p className="text-justify">
It would be appreciated if participants could review the forecasts and comments of other participants and remark on these when submitting their revised forecasts. Participants are also encouraged to make forecasts as often as they like if they feel their previous forecasts are no longer accurate.
</p>
<p className="text-justify">
To my surprise, the metal prices moved more than I had anticipated necessitating a recalibration of the forecast price range. The past forecasts have been adjusted to keep the previous average forecasted price more or less the same as had previously been calculated.
</p>
</div>
);
}
